<template>
  <div class="wl-footer">
    <div class="w">
      <div class="box">
        <div class="ul" v-for="(item, index) in list" :key="index">
          <div class="li t">{{ item.title }}</div>

          <div class="li" v-for="(i, ind) in item.ul" :key="ind">
            <a href="javascript:;" @click="uti.goPath(i.url)">
              {{ i.text }}
            </a>
          </div>
        </div>
        <div class="ul zz">
          <div class="logo">
            <img src="../assets/images/qrCode.png" alt="" />
            <a href="javascript:;" @click="trans()">
              <div class="but">点击资助</div>
            </a>
          </div>
          <div class="pbox">
            <div class="p">
              地址：<span class="addr"> {{ store.zzAddr }}</span>
            </div>
            <div class="p">
              我们接受USDT、PSC、BNB、Matic
              捐赠感谢你的捐赠，你的支持是我们前进的动力(支持PSC、Polygon、BNB
              Chain网络)。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../store';
export default {
  mounted() {},
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      list: [
        {
          title: '关于我们',
          ul: [
            {
              text: '联系我们',
              url: 'https://t.me/+rZwfr_U9vx5mNzU1',
            },
            {
              text: '意见反馈',
              url: 'https://t.me/+rZwfr_U9vx5mNzU1',
            },
          ],
        },
        {
          title: 'PSC官方',
          ul: [
            {
              text: 'PSC官网',
              url: 'https://www.polysmartchain.com/',
            },
            {
              text: 'PSC Bridge',
              url: 'https://bridge.polysmartchain.com',
            },
            {
              text: 'Swap',
              url: 'https://swanswap.polysmartchain.com',
            },
            {
              text: 'Pool',
              url: 'https://social.polysmartchain.com',
            },
          ],
        },
        {
          title: 'PSC官方社区',
          ul: [
            {
              text: 'Twitter',
              url: 'https://twitter.com/Polysmartchain',
            },
            {
              text: 'Discord',
              url: 'https://discord.gg/RtjASquGBr',
            },
            {
              text: 'Telegram',
              url: 'https://t.me/PSCoffivcial',
            },
            {
              text: 'Medium',
              url: 'https://medium.com/@polysmartchain',
            },
          ],
        },
        // {
        //   title: 'PSC华人社区',
        //   ul: [
        //     {
        //       text: 'Telegram',
        //       url: 'https://t.me/+rZwfr_U9vx5mNzU1',
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    // 转账(点击资助)
    trans2() {
      this.web3 = new this.$web3('https://seed1.PolySmartChain.com');
      if (!this.store.user.address) {
        return this.$message.error('请先登录');
      }
      let trans = {
        from: this.store.user.address,
        to: this.store.zzAddr,
        value: this.web3.utils.toHex(this.uti.amountMul('100', 18)),
        // data: "",
      };
      console.log(trans);

      window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [trans],
        })
        .then(() => {
          this.$message.success('资助成功,感谢你的资助');
          this.setVcant();
        })
        .catch(() => {
          return this.$message.error('资助失败');
        });
    },
    async trans() {
      this.web3 = new this.$web3('https://seed1.PolySmartChain.com');
      const transactionParameters = {
        nonce: '0x00', // ignored by MetaMask
        // gasPrice: "0x09184e72a000", // customizable by user during MetaMask confirmation.
        // gas: "0x2710", // customizable by user during MetaMask confirmation.
        to: this.store.zzAddr, // Required except during contract publications.
        from: this.store.user.address, // must match user's active address.
        value: this.web3.utils.toHex(this.uti.amountMul('100', 18)), // Only required to send ether to the recipient from the initiating external account.
        data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
        chainId: this.web3.utils.toHex(6999), // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
      };

      // txHash is a hex string
      // As with any RPC call, it may throw an error
      const txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      console.log(txHash);
    },
  },
};
</script>

<style lang="less" scoped>
.wl-footer {
  /* 矩形 6 */
  background: #ffffff;
  box-shadow: 0px -4px 10px 0px rgba(153, 153, 153, 0.144);
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  color: #999999;
  .w {
    .box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ul {
        display: inline-block;
        .li {
          line-height: 30px;
          font-weight: 500;
          font-family: SourceHanSansCN-Regular;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #333333;
          a {
            color: #333333;
          }
        }
        .t {
          margin-bottom: 20px;
          font-weight: bold;
          /* PSC官方社区 */
          font-family: SourceHanSansCN-Regular;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
      .zz {
        // width: 403px;
        text-align: center;
        display: flex;
        .logo {
          img {
            width: 100px;
          }
          .but {
            /* 矩形 2 */
            padding: 10px 0;
            margin: 10px auto;
            width: 93px;
            height: 32px;
            border-radius: 5px;
            background: #ff7d41;
            height: 40px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #ffffff;
          }
        }
        .pbox {
          padding-top: 30px;
          padding-left: 50px;
          .p {
            width: 403px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0em;
            margin-top: 10px;
            color: #999999;
            text-align: left;
            .addr {
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>
