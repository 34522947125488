<template>
  <div class="m-header">
    <div class="header">
      <!-- 移动端头部 -->

      <div class="ll">
        <svg @click="upMenu()" class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-caidan"></use>
        </svg>
      </div>
      <div class="item" @click="upUpLoad()">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-shangchuan"></use>
        </svg>
      </div>
      <div class="rr">
        <m-wallet-link></m-wallet-link>
      </div>
    </div>
    <wl-up-load ref="upload"></wl-up-load>
    <m-header-menu ref="menu"></m-header-menu>
  </div>
</template>

<script>
import WlUpLoad from '../../components/upLoad/wl-upLoad.vue';
import Store from '../../store';
import MHeaderMenu from './m-header-menu.vue';
import MWalletLink from './m-wallet-link.vue';
export default {
  components: { MWalletLink, MHeaderMenu, WlUpLoad },
  mounted() {},
  data() {
    return {
      ifShow: false,
      store: Store.state,
      uti: Store.uti,
    };
  },
  methods: {
    upMenu() {
      this.$refs.menu.up();
    },
    // 打开上传
    upUpLoad() {
      this.$refs.upload.up();
    },
  },
};
</script>

<style lang="less">
.m-header {
  position: relative;
  // width: 100vw;
  height: (60 / 3.75vw);
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    height: (60 / 3.75vw);
    padding: 0 (13 / 3.75vw);
    // background-color: #fff;
    display: flex;
    align-items: center;
    color: #000;
    z-index: 20;
    img {
      width: (20 / 3.75vw);
      height: (20 / 3.75vw);
    }
    font-size: (10.67 / 3.75vw);
    .ll {
      font-size: (20 / 3.75vw);
      font-weight: Medium;
      flex: 1;
      margin-left: (5 / 3.75vw);
    }
    .item {
      margin-right: (28 / 3.75vw);
      font-size: (24 / 3.75vw);
    }
    .rr {
      font-size: (20.67 / 3.75vw);
    }
  }
}
</style>
