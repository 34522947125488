<template>
  <div class="upload">
    <el-dialog
      title="上传视频"
      :visible.sync="dialogVisible"
      center
      @closed="down()"
    >
      <el-form :model="form" v-loading="loading">
        <el-form-item label="Youtube链接:" :label-width="formLabelWidth">
          <el-input
            @change="jxVideoDec(form.url)"
            v-model="form.url"
            :disabled="ifDis"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频标题:" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="视频分类:" :label-width="formLabelWidth">
          <el-select v-model="form.state" placeholder="请选视频分类">
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in stateList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="thumbnail"
          label="视频封面:"
          :label-width="formLabelWidth"
        >
          <el-upload
            class="avatar-uploader"
            :action="store.upLoadImg"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.img" :src="form.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item label="使用Youtube封面:" :label-width="formLabelWidth">
          <el-switch
            v-model="form.utbImgIf"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="upLoadVideo()">确认上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Store from "../../store";
// import word from "../../sensitiveWord";
export default {
  name: "upload",
  mounted() {
    // console.log(word("18禁"));
    // console.log(word("共产党"));
    // 获取视频分类
    this.getVideoStateList();
    if (window.innerWidth <= 700) {
      this.formLabelWidth = null;
    }
  },
  data() {
    return {
      // 是否显示
      dialogVisible: false,
      store: Store.state,
      dialogTableVisible: false,
      dialogFormVisible: false,
      // 链接地址是否可以输入
      ifDis: false,
      // 视频分类表
      stateList: [],
      form: {
        // 视频链接
        url: "",
        // 视频标题
        title: "",
        // 视频分类
        state: "",
        // 封面
        img: "",
        // 是否使用utb封面
        utbImgIf: true,
      },
      loading: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    up() {
      // this.ifShow = true;
      this.dialogVisible = true;
    },
    down() {
      // this.ifShow = false;
      this.form.url = "";
      this.form.state = "";
      this.form.title = "";
      this.form.img = "";
      this.ifDis = false;
      this.dialogVisible = false;
    },
    // 解析用户上传视频信息
    async jxVideoDec(url) {
      this.loading = true;
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(`/videoInfo/parseYoutube`, {
        url: url,
      });
      this.loading = false;
      if (res.code != 200) {
        this.down();
        return this.$message.error("视频解析失败，请重试");
      }
      this.ifDis = true;
      this.form.title = res.data.title;
      this.form.img = res.data.thumbnail;
    },
    // 获取视频分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${1}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      /*
        id: "1"
        sortNum: 1
        status: 1
        title: "什么是psc?"
        typeCode: 1
        */
      this.store.topics = res.data;
      this.stateList = res.data;
      // this.form.state = this.stateList[0].id;
    },
    // 提交视频
    async upLoadVideo() {
      // if (!word(this.form.title)) {
      //   return this.$message.error("标题包含敏感词汇");
      // }

      if (!this.store.user.userId) {
        return this.$message.error("请先登录");
      }
      if (!this.form.url) {
        return this.$message.error("请输入视频链接");
      }
      if (!this.form.title) {
        return this.$message.error("请输入视频标题");
      }
      if (!this.form.state) {
        return this.$message.error("选择分类");
      }
      let { data: res } = await this.$http.post("/videoInfo/addVideo", {
        //分类
        topicId: this.form.state,
        // 用户id
        userId: this.store.user.userId,
        // 封面
        videoThumbnail: this.form.img,
        // 视频标题
        videoTitle: this.form.title,
        // 视频链接
        youtubeUrl: this.form.url,
      });

      if (res.code !== 200) {
        return this.$message.error(res.message);
      }

      this.form.url = "";
      this.form.img = "";
      this.form.title = "";
      this.down();
      this.$message.success("提交成功");
    },

    handleAvatarSuccess(res, file) {
      this.form.img = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  /deep/.el-dialog {
    width: 900px;
  }
  .el-button {
    width: 747px;
    height: 63px;
    background-color: #ff7d41;
    border: none;
  }
  .el-form {
    width: 690px;
    margin: 0 auto;
    .el-form-item {
      height: 80px;
    }
    /deep/ .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #999;
    }
    .el-select {
      width: 100%;
      /deep/ .el-input__inner {
        border: 1px solid;
      }
    }
    .thumbnail {
      height: 309px;
      width: 100%;
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 567px;
        height: 309px;
        line-height: 309px;
        border: 1px solid #d9d9d9;
        text-align: center;
        border-radius: 10px;
      }
      .avatar {
        display: block;
        /* 矩形 8 */
        width: 100%;
        height: 309px;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #999999;
      }
    }
    .el-switch {
      float: right;
    }
  }
}
@media screen and (max-width: 700px) {
  .upload {
    /deep/ .el-dialog {
      width: (349 / 3.75vw);
    }
    /deep/ .el-button {
      width: 100%;
      margin-top: (20 / 3.75vw);
    }
    /deep/ .el-form {
      width: 100%;
      .el-form-item {
        margin-bottom: 0;
        // height: 60px;
      }
      .el-input {
        margin-top: 0;
      }
      .el-form-item__label {
        display: block;
      }
      .thumbnail {
        height: (150 / 3.75vw);
        width: 100%;
        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
          width: 100%;
        }
        .avatar-uploader-icon {
          font-size: (28 / 3.75vw);
          color: #8c939d;
          width: 100%;
          height: (150 / 3.75vw);
          line-height: (150 / 3.75vw);
          border: 1px solid #d9d9d9;
          text-align: center;
          border-radius: (10 / 3.75vw);
        }
        .el-upload.el-upload--text {
          width: 100%;
        }
        .avatar {
          display: block;
          /* 矩形 8 */
          width: 100%;
          height: (150 / 3.75vw);
          border-radius: (10 / 3.75vw);
          box-sizing: border-box;
          border: 1px solid #999999;
        }
      }
    }
  }
}
</style>
