<template>
  <div class="linkWallet">
    <!-- 显示在外面的样式 -->
    <div class="linkBut" v-if="!store.user.address" @click="linkWallet()">
      链接钱包
    </div>
    <div class="upLinkBut" v-else @click="login()">
      {{ uti.shrink(store.user.address) }}
    </div>
  </div>
</template>
<script>
// import tp from "tp-js-sdk";
import Store from '../../store';
export default {
  components: {},
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示弹窗
      popIfShow: false,
      // 是否链接钱包
      linkIfShow: false,
      // 是否自动链接
      zd: true,
    };
  },
  async mounted() {
    // 设置默认语言
    if (window.localStorage.getItem('lang')) {
      let lang = JSON.parse(window.localStorage.getItem('lang'));
      this.$i18n.locale = lang.node;
    }
    if (this.store.zd) {
      this.linkWallet();
    }
  },
  methods: {
    // 链接钱包
    async linkWallet() {
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((item) => {
          console.log(item[0]);
          this.store.user.address = item[0];
          this.login();
        });
    },
    // 判断当前网络是不是psc
    ifPsc() {
      if (window.ethereum.networkVersion != 6999) {
        // alert("123");
        this.$refs.wa.up('tips', 'please');
      }
      // this.$refs.wa.up("tips", "please");
    },
    // 断开钱包
    disconnectWallet() {
      Store.setWallet('');
      this.popIfShow = false;
      this.store.zd = false;
      this.store.userData.id = 0;
      Store.setUserDate(this.store.userData);
    },
    // 登录
    async login() {
      let tiem = new Date().getTime();
      let { data: res } = await this.$http.post('/userInfo/login', {
        address: this.store.user.address,
        mix: this.$md5(
          `tVTQ5vAJ1z5RslrXd5cz2iwfwsYTtnHi${this.store.user.address}${tiem}`
        ),
        timestamp: tiem,
      });
      if (res.code !== 200) {
        return this.$message.error('网络错误');
      }
      this.store.user.nickName = res.data.nickName;
      this.store.user.userId = res.data.userId;
      window.sessionStorage.setItem('authToken', res.data.authToken);
      window.sessionStorage.setItem('user', JSON.stringify(this.store.user));
    },
    logout() {
      this.store.user.address = '';
      this.store.user.userId = '';
    },
  },
};
</script>
<style lang="less" scoped>
.linkWallet {
  .linkBut {
    height: 20px;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ffffff;
    white-space: nowrap;
    display: inline-block;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background: #ff7d41;
  }
  .upLinkBut {
    height: 20px;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ffffff;
    /* 矩形 2 */
    display: inline-block;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background: #ff7d41;
  }
}
</style>
