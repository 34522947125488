<template>
  <div id="app">
    <m-header v-if="ifM"></m-header>
    <wl-header v-else></wl-header>
    <router-view />
    <!-- <m-footer v-if="ifM || false"></m-footer> -->
    <wl-footer v-if="!ifM"></wl-footer>
  </div>
</template>

<script>
import WlHeader from "./components/header/wl-header.vue";
import WlFooter from "./components/wl-footer.vue";
import MHeader from "./mCom/header/m-header.vue";
// import MFooter from "./mCom/m-footer.vue";
import Store from "./store";
export default {
  name: "app",
  components: {
    WlHeader,
    WlFooter,
    // MFooter,
    MHeader,
  },
  mounted() {
    window.myVue = this;

    // 获取传参
    // let id = window.location.search.substr(1);
    // if (id != "" && id.length > 5) {
    //   this.store.actVideoId = id;
    // }
    // if (window.location.pathname.indexOf("mVideoDec") >= 0) {
    //   this.ifM = true;
    //   this.$router.push("/mVideoDec");
    // }
    // 如果屏幕是小屏幕就跳到移动端;
    if (screen.width < 700) {
      this.ifM = true;
      if (window.location.pathname.indexOf("video") >= 0) {
        let id = window.location.search.substr(1);
        if (id != "" && id.length > 5) {
          this.store.actVideoId = id;
        }
        this.$router.push("/mVideoDec");
      } else {
        this.$router.push("/m");
      }
    } else {
      this.ifM = false;
      // this.$router.push("/");
    }
    // if (window.sessionStorage.getItem("path")) {
    //   this.$router.push(window.sessionStorage.getItem("path"));
    // }
  },
  data() {
    return {
      ifM: false,
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>

<style lang="less">
@import url("./assets/style.less");

@font-face {
  font-family: "ARENO";
  src: url("./assets/fonts/ARENO-SERIF.OTF") format("woff");
}
.areno {
  font-family: "ARENO";
}
#app {
  @font-face {
    font-family: "ubuntu";
    src: url("./assets/fonts/Ubuntu-L_W.woff2") format("woff2");
  }
  // font-family: SourceHanSansCN;
  font-family: arialbd, SourceHanSansCN, ubuntu;
  // background: #f4faff;
}
.w {
  width: 1640px;
  margin: 0 auto;
}
.wl-avatar {
  width: 46px;
  height: 46px;
  color: #fff;
  line-height: 46px;
  background: #41b6ff;
  border-radius: 5px;
  text-align: center;
}
@media screen and (max-width: 1640px) {
  .w {
    width: 1440px;
  }
}
@media screen and (max-width: 1440px) {
  .w {
    width: 1240px;
  }
}
@media screen and (max-width: 700px) {
  /deep/ .el-tabs {
    margin: 0 (18 / 3.75vw);
    .van-list__finished-text,
    .van-list__placeholder {
      display: block !important;
      width: 100% !important;
    }
  }
}
.mActList {
  margin: 0 (18 / 3.75vw);
  // display: flex;
  // justify-content: space-around;
  margin-top: (21 / 3.75vw);
  white-space: nowrap;

  overflow: auto;

  .item {
    margin-right: (15 / 3.75vw);
    padding: (10 / 3.75vw) 0;
    font-size: (14 / 3.75vw);
    border-bottom: 1px solid #0000;
    display: inline-block;
    /* 全部 */
  }
  .act {
    border-bottom: 1px solid #ff7d41;
    color: #ff7d41;
    // border-image: -webkit-linear-gradient(#ff942c, #fd2894) 20 20;
  }
}

/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: none !important;
}
</style>
