import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import('../components/home/index.vue')
    redirect: '/square'
  },
  {
    path: '/search',
    component: () => import('../components/search/incex.vue')
  },
  {
    path: '/home',
    component: () => import('../components/home/index.vue')
  },
  {
    // 视频广场 
    path: '/square',
    component: () => import('../components/square/index')
  },
  {
    // 手记
    path: '/word',
    component: () => import('../components/word/index')
  },
  {
    // 手记详情
    path: '/wordDet',
    component: () => import('../components/word/word-det')
  },
  {
    path: '/video',
    component: () => import('../components/video/index')
  },
  {
    // 合约地址
    path: '/contAddr',
    component: () => import('../components/contAddr/index')
  },
  {
    path: '/personal',
    name: 'home',
    component: () => import('../components/personal/index'),
    children: [
      {
        // 我的视频
        path: 'myVideo',
        component: () => import('../components/personal/myVideo'),
      },
      {
        // 我的视频
        path: '',
        component: () => import('../components/personal/myVideo'),
      },
      {
        // 我的订阅
        path: 'subs',
        component: () => import('../components/personal/subS'),
      },
      {
        // 我的收藏
        path: 'collect',
        component: () => import('../components/personal/collect'),
      },
      {
        // 我的粉丝
        path: 'follows',
        component: () => import('../components/personal/follows'),
      },
      {
        // 我的视频收入
        path: 'videoIncome',
        component: () => import('../components/personal/income'),
      },
    ],
  },
  {
    path: '/m',
    component: () => import('../mCom/home')
  },
  {
    path: '/mPersonal',
    component: () => import('../mCom/personal'),
    children: [
      {
        // 我的视频
        path: 'myVideo2',
        component: () => import('../mCom/personal/myVideo'),
      },
      {
        // 我的视频
        path: '',
        component: () => import('../mCom/personal/myVideo'),
      },
      {
        // 我的订阅
        path: 'subs',
        component: () => import('../mCom/personal/subS'),
      },
      {
        // 我的收藏
        path: 'collect',
        component: () => import('../mCom/personal/collect'),
      },
    ],
  },
  {
    // 收益
    path: '/mIncome',
    component: () => import('../mCom/income')
  },
  {
    // 手记
    path: '/mWord',
    component: () => import('../mCom/word')
  },
  {
    // 手记详情
    path: '/mWorddet',
    component: () => import('../mCom/word/word-det.vue')
  },

  {
    // 合约地址
    path: '/mContAddr',
    component: () => import('../mCom/contAddr/index')
  },
  {
    // 粉丝
    path: '/mFollows',
    component: () => import('../mCom/follows')
  },
  {
    path: '/mUpload',
    component: () => import('../mCom/upload')
  },
  {
    path: '/mVideoDec',
    component: () => import('../mCom/video/video-details.vue')
  },
  {
    path: '/mAbout',
    component: () => import('../mCom/m-about.vue')
  },
  {
    path: '*',
    component: () => import('../views/page404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.afterEach((to, from, next) => {
  console.log(to + from + next);
  window, scrollTo(0, 0)
})
// 全局前置导航守卫 
router.beforeEach((to, from, next) => {

  // 如果前往个人页面，就判断他的路由
  if (['/personal', '/mPersonal', '/mIncome', '/mFollows'].indexOf(to.path) >= 0 && store.state.user.userId == '') {
    window.myVue.$message.warning('请先登录')
    return
  } else {
    next()
  }

})


export default router
