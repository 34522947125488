<template>
  <div class="header-manu" @click="down()" v-if="ifShow">
    <div class="box" @click.stop="">
      <div class="userBox" v-if="store.user.userId">
        <avatar-item></avatar-item>
        <div class="addr">地址：{{ uti.shrink(store.user.address, 6) }}</div>
      </div>
      <div
        :class="{ act: $route.path == item.path }"
        @click="goPath(item.path)"
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="index"
      >
        {{ item.name }}
      </div>
      <div class="menu-item" @click="dk()">退出登录</div>
    </div>
  </div>
</template>
<script>
import Store from '../../store';
import avatarItem from '../../components/avatar/avatar-item.vue';
export default {
  components: { avatarItem },
  data() {
    return {
      ifShow: false,
      store: Store.state,
      uti: Store.uti,
      menuList: [
        {
          path: '/M',
          name: '视频广场',
        },
        {
          path: 'mWord',
          name: 'PSC手记',
        },
        {
          path: 'mContAddr',
          name: '合约地址',
        },
        {
          path: '/mPersonal',
          name: '我的资料',
        },
        {
          path: 'mIncome',
          name: '视频收益',
        },
        {
          path: 'mFollows',
          name: '我的关注',
        },
        {
          path: 'mAbout',
          name: '关于我们',
        },
        // {
        //   path: "",
        //   name: "合约地址",
        // },
      ],
    };
  },
  methods: {
    up() {
      this.ifShow = true;
    },
    down() {
      this.ifShow = false;
    },
    goPath(val) {
      this.down();
      this.uti.goPath(val);
    },
    // 断开钱包
    dk() {
      this.down();
      this.store.user.address = '';
      this.store.user.userId = '';
      this.store.user.nickName = '';
      this.store.user.authToken = '';
      window.sessionStorage.removeItem('authToken');
      window.sessionStorage.removeItem('user');
      if (
        ['/mPersonal', '/mIncome', '/mFollows'].indexOf(this.$route.path) >= 0
      ) {
        this.$router.push('/m');
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header-manu {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.616);
  position: fixed;
  z-index: 20;
  .box {
    padding: (18 /3.75vw);
    color: #999999;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    .userBox {
      margin-bottom: (20 /3.75vw);
      .addr {
        margin-top: 9px;
      }
    }
    .menu-item {
      height: (38 /3.75vw);
    }
    .act {
      color: #ff7d41;
    }
  }
}
</style>
