<template>
  <div class="wl-header" :class="{ shen: !store.ifTop }">
    <div class="w">
      <el-menu id="elMenu" :default-active="activeIndex" mode="horizontal">
        <el-menu-item>
          <img
            @click.stop=""
            class="logo"
            src="../../assets/images/logo.png"
            alt=""
          />
        </el-menu-item>
        <!-- <el-submenu index="2">
          <template slot="title" >视频广场</template>
          <el-menu-item index="2-1" @click="uti.goPath('/video')"
            >什么是PSC</el-menu-item
          >
          <el-menu-item index="2-2">PSC有何优势</el-menu-item>
          <el-menu-item index="2-3">不同的标题标签</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="2" @click="menuAct(2)">视频广场</el-menu-item>
        <el-menu-item index="3" @click="menuAct(3)">PSC手记</el-menu-item>
        <el-menu-item index="4" @click="menuAct(4)">合约地址 </el-menu-item>
        <div class="fr">
          <el-input
            v-if="$route.path != '/search'"
            v-on:keyup.enter.native="uti.goPath('/search')"
            placeholder="输入搜索视频标题"
            v-model="store.serchVal"
            class="input-with-select"
          >
            <!-- suffix-icon="el-icon-search" -->
            <el-button
              @click="uti.goPath('/search')"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-button
            @click="upUpLoad()"
            class="upload"
            slot="append"
            icon="el-icon-search"
          >
            上传视频
          </el-button>
          <div class="user" @click="menuAct(5)">
            <el-avatar
              v-if="false"
              shape="square"
              size="large"
              src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
            ></el-avatar>
            <!-- <div class="wl-avatar">
              {{ uti.setAvatar(store.user.nickName) }}
            </div> -->
            <avatar-item v-if="store.user.userId"></avatar-item>
            <div class="text">
              <wallet-link></wallet-link>
            </div>
            <div class="dk" @click.stop="dk()" v-if="store.user.userId">
              断开
            </div>
          </div>
        </div>
      </el-menu>
    </div>
    <up-load ref="upload"></up-load>
  </div>
</template>

<script>
import Store from '../../store';
import AvatarItem from '../avatar/avatar-item.vue';
import UpLoad from '../upLoad/wl-upLoad.vue';
import WalletLink from './wallet-link.vue';
export default {
  components: { UpLoad, WalletLink, AvatarItem },
  mounted() {
    // const that = this;
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth;
    //     that.screenWidth = window.screenWidth;
    //   })();
    // };
    // 监听到顶部的位置
    window.addEventListener('scroll', this.handleScroll);
    if (window.sessionStorage.getItem('headerAct')) {
      this.activeIndex = window.sessionStorage.getItem('headerAct');
    }
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      screenWidth: document.body.clientWidth,
      activeIndex: '2',
      input3: '',
    };
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop == 0) {
        // console.log(scrollTop);
        this.store.ifTop = true;
      } else {
        this.store.ifTop = false;
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 链接钱包
    // 打开上传
    upUpLoad() {
      this.$refs.upload.up();
    },
    menuAct(ind) {
      this.activeIndex = ind + '';
      if (ind == 1) {
        // this.uti.goPath('/square');
      } else if (ind == 2) {
        this.uti.goPath('/square');
      } else if (ind == 3) {
        this.uti.goPath('/word');
      } else if (ind == 4) {
        this.uti.goPath('/contAddr');
      } else if (ind == 5) {
        this.uti.goPath('/personal');
      }
      window.sessionStorage.setItem('headerAct', this.activeIndex);
    },
    dk() {
      this.store.user.address = '';
      this.store.user.userId = '';
      this.store.user.nickName = '';
      this.store.user.authToken = '';
      window.sessionStorage.removeItem('authToken');
      window.sessionStorage.removeItem('user');
      if (this.$route.path.indexOf('/personal') >= 0) {
        this.$router.push('/');
      }
    },
  },
};
</script>
<style lang="less">
.wl-header {
  // position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // height: 150px;
  background-color: rgb(250, 250, 250);
  // background: #2f7deb96;
  color: #fff;
  font-size: 18px;
  z-index: 98;
  transition: 0.5s;
  display: flex;
  align-items: center; /* 矩形 32 */

  height: 100px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 10px 0px rgba(187, 187, 187, 0.158);
  margin-bottom: 25px;
  .logo {
    height: 70px;
    // width: 80px;
  }

  .w {
    /deep/ .el-menu.el-menu--horizontal {
      background: rgba(255, 255, 255, 0.8);
      height: 100px;
      border: none !important;
    }
    #elMenu {
      border: none !important;
      .el-menu-item {
        font-size: 18px;
      }
      .el-menu-item:nth-child(1) {
        font-size: 25px;
        border: none;
      }
      .is-active {
        // background-color: #ff7d41;
        border-color: #ff7d41;
        font-weight: bold;
      }
    }
    .fr {
      height: 80px;
      display: flex;
      align-items: center;
      .el-input {
        height: 46px;
        input {
          height: 46px;
        }
      }
      .upload {
        margin-left: 36px;
        height: 46px;
        background-color: #4144ff;
        color: #fff;
      }
      .user {
        display: flex;
        align-items: center;
        .el-avatar {
          width: 46px;
          height: 46px;
          display: inline-block;
        }
        .text {
          display: inline-block;
          margin: 0 20px;
          height: 46px;
          // width: 120px;
          line-height: 46px;
        }
        margin-left: 36px;
        font-size: 14px;
        height: 46px;
        border-radius: 5px;
        background: #ff7d41;
      }
      .user:hover .dk {
        // display: block;
        // height: 1px;
      }
      .dk {
        // position: absolute;
        // bottom: -26px;
        // right: 0;
        display: none;
        font-size: 13px;
        width: 60px;
        text-align: center;
        margin-left: 20px;
        height: 46px;
        line-height: 46px;
        background-color: rgb(248, 77, 77);
        border-radius: 5px;
      }
    }
  }
  // 下级菜单样式
  .header-lang {
    position: relative;
    .mini {
      height: 80px;
      line-height: 80px;
      a {
        color: #fff;
      }
    }
    .box {
      position: absolute;
      top: 75px;
      width: 720px;
      // height: 428px;
      background: #ffffff;
      box-shadow: 0px 5px 20px 0px rgba(23, 82, 161, 0.5);
      border-radius: 10px;
      padding: 20px 20px;
      box-sizing: border-box;
      display: flex;
      // display: none;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 9;

      .li {
        width: 340px;
        height: 116px;
        // margin-top: 10px;
        // background: #f1f6ff;
        border-radius: 10px;
        color: #000;
        display: inline-flex;
        padding: 10px;
        box-sizing: border-box;
        .img {
          width: 76px;
          padding-top: 13px;
        }
        .span {
          flex: 1;
          .name {
            font-size: 24px;
            margin-top: 13px;
          }
          .text {
            font-size: 14px;
            margin-top: 5px;
            color: #999;
          }
        }
      }
      .li:hover {
        background: #f1f6ff;
        color: #0168ec;
        .text {
          color: #97c1f8;
        }
      }
    }
    .box::after {
      content: '';
      width: 25px;
      height: 30px;
      background-image: url('../../assets/images/v4/header/err.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -15px;
      left: 40px;
      z-index: 8;
    }
  }
}
// .shen {
//   background: #2f7deb;
// }
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.3s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
</style>
