import Vue from 'vue'
import 'vant/lib/index.css';
// import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import Web3 from 'web3'
import md5 from 'js-md5'
import Vant from 'vant';
import 'windi.css'
// import store from './store'
// import VueI18n from 'vue-i18n'


Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.$md5 = md5

Vue.prototype.$web3 = Web3


// 导入挂载axios
Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://10.192.1.101:9999'
// axios.defaults.baseURL = 'http://10.192.1.71:8080'
axios.defaults.baseURL = 'https://www.polycollege.club/app'

// 添加拦截器，为每次请求添加token
axios.interceptors.request.use((config) => {
  if (window.sessionStorage.getItem('authToken')) {
    config.headers.Auth = window.sessionStorage.getItem('authToken')
  }
  if (window.myVue.store.user.userId) {
    config.headers.Address = window.myVue.store.user.address
  }
  return config;
});


// Vue.use(VueI18n)

// 获取文字配置
let messages = {}
for (let i = 0; i < window.wl.lang.length; i++) {
  messages[window.wl.lang[i].lang.node] = window.wl.lang[i]
}
// 创建i18n实例
// const i18n = new VueI18n({
//   // 这是设置语言包，默认语言， 
//   locale: 'zh-cn',
//   messages: messages
// })


new Vue({
  router,
  // i18n,
  render: h => h(App)
}).$mount('#app')
