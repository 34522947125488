import Vue from 'vue'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import { MessageBox, Option, Avatar, Button, Carousel, CarouselItem, Col, Dialog, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Input, Menu, MenuItem, MenuItemGroup, Message, Row, Select, Slider, Submenu, TabPane, Tabs, Switch, Pagination, Loading, Upload, Icon } from 'element-ui'

// Vue.use(Button)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(Slider)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Switch)
Vue.use(Pagination)
Vue.use(Upload)
// Vue.use(Loading)


Vue.component(CollapseTransition.name, CollapseTransition)


Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;