// 共享数据
import Decimal from 'decimal.js'


const Store = {
	state: {
		// 图片上传地址
		// upLoadImg: 'https://www.polycollege.club/app/common/upload',
		upLoadImg: 'https://www.polycollege.club/app/common/upload',
		// 资助地址
		zzAddr: '0x004AaD688522f61B195aEDEdc5e032871D9573E7',
		// 公用头像
		avatar: 'https://polyjetclub-backend.polysmartchain.com/images/normal/866.png',
		// 打赏合约
		shangCont: '0x1530427F0D722229e91a7633b6b42E26Ac83C226',
		// 点赞合约
		vantCont: '0xfb323274B1757FdBCe1dC04154EDbDeA719935E7',
		user: {
			address: '',
			authToken: "40889879b87145bfbd40e7256e88d218",
			nickName: "4ab2bA",
			userId: ""
		},
		// 搜索内容
		serchVal: '',
		// 主题清单
		topics: [
			// 	{
			// 		id: "1"
			// 		sortNum: 1
			// 		status: 1
			// 		title: "什么是PSC"
			// 		typeCode: 1
			// 	}
		],
		topicsDoc: [],
		// 选中的视频id
		actVideoId: '1527495915995271168',
		// 视频的宽高
		styleObject: {
			width: "100%",
			height: "720px",
		},

		// 选中的视频的详情
		actVideoDec: {
			boutiqueStatus: "", //	是否精品 1.是 0.否	integer(int32)
			collected: "", //	是否已收藏 1.是 0.否	integer(int32)
			followed: "", //	是否已关注作者 1.是 0.否	integer(int32)	
			createTime: "", //	创建时间	string(date)
			id: "", //	视频id	integer(int64)
			loveNum: "", //	点赞量	integer(int32)
			loved: "", //	是否已点赞 1.是 0.否	integer(int32)
			playNum: "", //	播放量	integer(int32)
			updateTime: "", //	更新时间	string(date)
			userNickName: "", //	用户昵称	string
			videoThumbnail: "", //	视频封面	string
			videoTitle: "", //	视频标题	string
			videoUrl: "", //		视频播放地址
			userAddress: "", //发布者钱包地址	string
			userId: "", //发布者id	integer(int64)	
		},
		// 选中视频的评论列表
		actVideoComList: [
			// {
			// 	children: [
			// 	],//下级评论
			// 	contentText: '',//	评论内容	string	
			// 	createTime: '',//	评论时间	string	
			// 	id: '',//	id	integer	
			// 	nickName: '',//	昵称	string	
			// 	userId: '',//	发表的用户id
			// 	// targetUserId: '',//	回复目标用户的id	integer	
			// 	targetUserNickName: '',//	回复目标用户的昵称	string
			// },
		],
		// 关注粉丝列表
		gzFsList: [
			// {
			// 	address: '0xA424ED165B0a3aa46fC649B85069383de44B424D',//被关注用户地址	string	
			// 	id: '123456798',//被关注用户id	integer	
			// 	nickName: '张三',//	被关注用户昵称	string
			// },
		],
		// 粉丝列表
		List: [{
			address: '123456',//	被关注用户地址	string	
			followed: 1,//	已关注对方 1.是 0.否	integer	
			id: '123456798',//	被关注用户id	integer	
			nickName: '张三',//	被关注用户昵称	string
		}],
		// 个人统计数据
		personalDec: {
			fansNum: 0, //粉丝	integer(int64)
			followedNum: 0, //关注	integer(int64)
			lovesNum: 0, //获赞	integer(int64)
			rewardsNum: 0, //打赏收入	integer(int64)
		},
		// 我的视频列表
		myVideoList: [
			// 	{
			// 	authStatus: '',//	审核状态 0.待审核 1.审核通过 2.审核不通过	integer	
			// 	boutiqueStatus: '',//	是否精品 1.是 0.否	integer	
			// 	createTime: '',//	创建时间	string	
			// 	id: '',//	视频id			integer	
			// 	loveNum: '',//	点赞量	integer	
			// 	playNum: '',//	播放量	integer	
			// 	updateTime: '',//	更新时间	string	
			// 	userNickName: '',//	用户昵称	string	
			// 	videoThumbnail: '',//	视频封面	string	
			// 	videoTitle: '',//	视频标题	string
			// }
		],

		// 选中的文档id
		actDocId: '1527495915995271168',

	},
	// 设置地址
	setActVideoId (val) {
		this.state.actVideoId = val
		window.sessionStorage.setItem('actVideoId', val)
	},

	// 设置地址和链id
	setWallet (val, id) {
		this.state.walletAddress = val
		this.state.walletNetworkId = id
		window.sessionStorage.setItem('walletAddress', val)
		window.sessionStorage.setItem('walletNetworkId', id)
		Store.uti.setActNetwork()
		Store.uti.getBalnasACT()
		Store.uti.getAuthorizeBalnasACT()
	},
	uti: {
		// 跳转网页或跳转路由
		goPath (path) {
			if (path == '') {
				return
			}
			if (path.indexOf('http') == 0) {
				// window.location = path
				window.open(path, "_blank");
			} else {
				// if (screen.width < 700) {
				// 	window.myVue.$router.push(path + 'M')
				// 	window.sessionStorage.setItem('path', path + 'M')
				// 	Store.state.menuIfshow = false
				// } else {
				// }
				window.myVue.$router.push(path)
				window.sessionStorage.setItem('path', path)
			}
		},
		/**
		 *  收起字符串
		 * 第一个参数是要收起的字符串
		 * 第二个参数是收起后剩余几位（默认保留4位数）
		 * 第三个参数是收起前后还是收起中间 c:收起两边，lr：收起中间（默认收起中间）
		 */

		//  第一个参数是要收缩的字符串第二个参数是前后剩余几位（不传默认为四位）
		shrink (val, num, lcr) {
			if (!val) {
				return "";
			}
			if (!num) {
				num = 4;
			}
			if (!lcr) {
				lcr = 'lr'
			}
			// 如果是收起中间
			if (lcr == 'c') {
				let center = Math.floor(val.length / 2)
				return (
					'···' +
					val.substring(center - num, center + num)
					+ '···'

				);
			}
			// 默认中间
			return (
				val.substring(0, num) +
				"···" +
				val.substring(val.length - num, val.length)
			);

		},
		// 计算属性 为数字添加千分位 不截断后面
		addThousand3 (num) {
			let val = String(num);
			let reg = /\d{1,3}(?=(\d{3})+$)/g;
			if (val.indexOf(".") == -1) {
				return (val + "").replace(reg, "$&,");
			} else {
				let ind = val.indexOf(".");
				let left = String(val).substring(0, ind);
				left = (left + "").replace(reg, "$&,");
				let right = String(val).substring(ind + 1, val.lenght);
				// if (right.length > 5) {
				//   right = `${right.substring(0, 4)}`
				// }
				return `${left}.${right}`;
			}
		},
		// 判断谁大
		amountMax (amL, amR) {
			// 首先判断位数 哪个位数长就哪个大
			return Number(Decimal.max(amL, amR)).toLocaleString().replace(/,/g, "");
		},
		// 字符串数值减法
		reduce (a, b) {
			// 判断小数位数
			let ari = 0;
			let bri = 0;
			let ale = a.length;
			let ble = b.length;
			if (a.indexOf(".") > 0) {
				ale = a.indexOf(".");
				ari = a.length - a.indexOf(".") - 1;
			}
			if (b.indexOf(".") > 0) {
				ble = b.indexOf(".");
				bri = b.length - b.indexOf(".") - 1;
			}
			// 小数 补0 如果b的后面位数长于a则给a补零 否则给b补0
			if (bri > ari) {
				if (ari == 0) {
					a = a + ".";
				}
				for (let i = 0; i < bri - ari; i++) {
					a = a + "0";
				}
			}
			if (ari > bri) {
				if (bri == 0) {
					b = b + ".";
				}
				for (let i = 0; i < ari - bri; i++) {
					b = b + "0";
				}
			}
			// 整数补0
			if (ale > ble) {
				for (let i = 0; i < ale - ble; i++) {
					b = "0" + b;
				}
			}
			if (ble > ale) {
				for (let i = 0; i < ble - ale; i++) {
					a = "0" + a;
				}
			}
			// 相减
			let arr = [];
			for (let i = 0; i < a.length; i++) {
				if (typeof b[b.length - 1 - i] == undefined) {
					arr.push(a[a.length - 1 - i]);
				} else {
					arr.push(a[a.length - 1 - i] - b[b.length - 1 - i]);
				}
			}
			// 处理借位
			for (let i = 0; i < arr.length; i++) {
				if (isNaN(arr[i])) {
					arr[i] = ".";
				}
				if (arr[i] < 0) {
					if (isNaN(arr[i + 1])) {
						arr[i + 2]--;
					} else {
						arr[i + 1]--;
					}
					arr[i] += 10;
				}
			}
			// 最终结果
			let jg = "";
			for (let i = arr.length - 1; i >= 0; i--) {
				jg += arr[i];
			}
			// 如果第一个数为0 则去掉
			if (jg.indexOf(".") > 1 && jg[0] == "0") {
				jg = jg.substring(1);
			}
			return jg;
		},
		// 给数字保留两位小数
		toFixed (val, num) {
			// 如果传入的不是数字
			if (isNaN(Number(val))) {
				return val;
			}
			return Number(val).toFixed(num)
		},
		// 添加decimals
		/*
			amountMul (val, dec) {
				if (!dec) {
					dec = 18
				}
				let a = new Decimal(val)
				let b = new Decimal(`1e+${dec}`)
				return Number(new Decimal(a).mul(b)).toLocaleString().replace(/,/g, "");
			},
		*/

		// 乘decimal
		amountMul (val, dec) {
			if (!dec) {
				dec = 18
			}
			let ind = val.indexOf('.')
			let b = ''
			if (ind == -1) {
				for (let i = 0; i < dec; i++) {
					b += '0'
				}
			} else {
				let c = val.length - ind - 1
				for (let i = 0; i < dec - c; i++) {
					b += 0
				}
			}
			b = val.replace('.', '') + b
			return b
		},
		// 除Decimal 
		addDec (val, dec) {
			if (!dec) {
				dec = 18
			}
			let a = new Decimal(val)
			let b = new Decimal(`1e+${dec}`)
			return Number(new Decimal(a).div(b))
		},
		// 转换数据类型
		toStringWl (val) {
			let v = val.d.join('')
			// console.log(val);
			// console.log(val.d);
			// console.log(v);
			let n = val.s + val.e
			if (n < 0) {
				let z = ''
				for (let i = 0; i > n; i--) {
					z += '0'
				}
				let l = '0'
				let r = v.substr(0, 12 + n)
				console.log(`${l}.${z}${r}`);
				return Number(`${l}.${z}${r}`)
			} else {
				let l = v.substr(0, n)
				let r = v.substr(n, 12)
				console.log(`${l}.${r}`);
				return Number(`${l}.${r}`)

			}

		},
		// 获取配置文件的文字
		tipsText (val) {
			console.log(val);
			// 當前為中文還是英文
			let lang = window.myVue.$i18n.locale
			// 返回提示文字 
			let ts = window.myVue.$i18n.messages[lang].tips[val]
			// 如果有提示則返回，沒有則返回提示名 
			if (ts) {
				return ts
			} else {
				return (val)
			}
		},
		// 计算是否还有下一页
		isMore (total, num) {
			if (total > num * 10) {
				return true
			} else {
				return false
			}
		},
		// 合并两个数组
		addArr (arr1, arr2) {
			for (let i = 0; i < arr2.length; i++) {
				arr1.push(arr2[i])
			}
			return arr1
		},
		// 处理时间
		changeTime (arr) {
			return `${arr[0]}-${arr[1]}-${arr[2]}`
		},
		// 取名字当头像
		setAvatar (nickName) {
			nickName = String(nickName)
			return nickName.substr(nickName.length - 1, 1)
		},
	},

}
// if (window.sessionStorage.getItem('path')) {
// 	window.myVue.$router.push(window.sessionStorage.getItem('path'))
// }

if (window.sessionStorage.getItem('user')) {
	Store.state.user = JSON.parse(window.sessionStorage.getItem('user'))
}
if (window.sessionStorage.getItem("actVideoId")) {
	Store.state.actVideoId = window.sessionStorage.getItem("actVideoId");
}

export default Store