<template>
  <div class="avatar-item" :style="styleObj()" :class="'a' + getNum()">
    <div class="name">
      {{ uti.setAvatar(this.name) || uti.setAvatar(store.user.nickName) }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  props: {
    size: {
      type: Number,
      default() {
        return 46;
      },
    },
    addr: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    getNum() {
      if (this.addr.length == 0) {
        let a = this.store.user.userId.substr(
          this.store.user.userId.length - 1,
          1
        );
        return a;
      } else {
        let a = this.addr.substr(this.addr.length - 1, 1);
        return a;
      }
    },
    getName() {
      if (this.name.length == "") {
        let a = this.store.user.name.substr(this.store.user.name.length - 1, 1);
        return a;
      } else {
        let a = this.name.substr(this.name.length - 1, 1);
        return a;
      }
    },
    styleObj() {
      return {
        width: this.size + "px",
        height: this.size + "px",
        lineHeight: this.size + "px",
        fontSize: 0.4 * this.size + "px",
      };
    },
  },
};
</script>
<style lang="less" scoped>
.avatar-item {
  color: #000;
  // background: #41b6ff;
  border-radius: 5px;
  text-align: center;
  // background-color: red;
}

.a0 {
  background-color: rgb(243, 237, 201);
}
.a1 {
  background-color: rgb(197, 201, 238);
}
.a2 {
  background-color: rgb(204, 174, 180);
}
.a3 {
  background-color: rgb(250, 213, 208);
}
.a4 {
  background-color: rgb(231, 204, 248);
}
.a5 {
  background-color: rgb(207, 250, 226);
}
.a6 {
  background-color: rgb(213, 198, 255);
}
.a7 {
  background-color: rgb(250, 242, 209);
}
.a8 {
  background-color: rgb(210, 247, 201);
}
.a9 {
  background-color: rgb(212, 252, 250);
}
</style>
